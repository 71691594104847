// Loading the react components
import React from 'react';

// CSS
import "../../../css/base.css"
import "../../../css/Doctrine/doctrine.css"

export default class DoctrineInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>Hello, world!</div>
        )
    }
}