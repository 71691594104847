// Loading the react components
import React from 'react';

export default class MarketMain extends React.Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>Text</div>
        )
    }
}