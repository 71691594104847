// Loading the react components
import React from 'react';

// Loading Matieral UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


// CSS
import "../../../css/Admin/adminaccountslist.css"

// Class that will load and display all of the members
// Will be loaded within admin account and passed down as a prop
export default class AdminAccountsList extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            menu_anchor: null,
            target_sort: "account_name",
            skill_queue: [],
            skill_queue_popup_name: "",
            show_skill_queue: false
        }

        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.toggleSort = this.toggleSort.bind(this);
        this.showSkillQueuePopup = this.showSkillQueuePopup.bind(this);
        this.hideSkillQueuePopup = this.hideSkillQueuePopup.bind(this);
    }

    handleMenuOpen(event, character_id) {
        console.log(event);
        console.log(character_id);
        this.setState({menu_anchor: event.currentTarget, target_character: character_id});
    }

    handleMenuClose(value) {
        
        this.setState({menu_anchor: null});

        if (value.toString().length>10) {
            return;
        }

        this.props.setCharacterAccountGroup(this.state.target_character, value);
    }

    toggleSort(sortTarget) {
        this.setState({target_sort: sortTarget});
    }

    showSkillQueuePopup(skill_queue, character_name) {
        let display = [];
        for (let sidx in skill_queue) {
            let s = skill_queue[sidx];
            display.push(<div className="skillQueueLineEntry">{s.skill + " to Level " + s.level}</div>)
        }
        this.setState({show_skill_queue: true, skill_queue: display, skill_queue_popup_name: character_name});
    }

    hideSkillQueuePopup() {
        this.setState({show_skill_queue: false});
    }

    render() {

        let table_rows = [];

        let skill_queue_popup = <div className = "skillQueuePopup">
            <div className = "skillQueuePopupContainer">
                <div className = "skillQueueTitle">Skill Queue for {this.state.skill_queue_popup_name}</div>
                <div className = "skillQueue">
                    {this.state.skill_queue}
                </div>
                <div onClick={() => {this.hideSkillQueuePopup();}} className = "skillQueueTitle">Close</div>
            </div>
        </div>

        if (!this.state.show_skill_queue) {
            skill_queue_popup = <div />;
        }
        
        if (this.props.all_accounts != undefined) {
            const all_accounts = this.props.all_accounts

            all_accounts.sort((a, b) => (a[this.state.target_sort] > b[this.state.target_sort]) ? 1 : -1)

            let i = 0;
            let cidx = 0;
            let ccc = all_accounts[0].account_name;
            
            for (let rowidx in all_accounts) {
                let row = all_accounts[rowidx];

                if (ccc != row.account_name) {
                    ccc = row.account_name;
                    if (cidx == 0) {
                        cidx = 1;
                    }
                    else if (cidx == 1) {
                        cidx = 0;
                    }
                }

                i = i + 1;

                let skill_entry = row.skill_queue[0] == undefined ? "" : row.skill_queue[0].skill + " to " + row.skill_queue[0].level;
                console.log(row.skill_queue[0] == undefined, row.skill_queue[0], skill_entry);
                const r = <TableRow key={i}>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} component="th" scope="row">{row.character_index}</TableCell>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} component="th" scope="row">{row.account_name}</TableCell>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} align="left">{row.character_name}</TableCell>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} align="left">{row.corporation_name}</TableCell>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} align="left">{row.system}</TableCell>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} align="left">{row.system_tag}</TableCell>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} align="left">{row.structure}</TableCell>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} align="left">{row.ship}</TableCell>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} align="left" onClick={() => {this.showSkillQueuePopup(row.skill_queue, row.character_name);}}>
                        {<div className="skillQueueLineEntry">{skill_entry}</div>}
                    </TableCell>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} align="left">{row.character_config_tag_1}</TableCell>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} align="left">{row.character_config_tag_2}</TableCell>
                    <TableCell className = {"accounts_table_row_cell_" + cidx} align="left">{row.character_config_tag_3}</TableCell>

                    {/*<TableCell className = "accounts_table_row_cell" align="left" onClick={(e) => this.handleMenuOpen(e, row.character_id)}>
                        {row.character_type_title}
                        <i class="fa fa-chevron-down dropdown-arrow-group"></i>
                    </TableCell>*/}
                    {/*<TableCell className = "accounts_table_row_cell" align="left">{row.init_date}</TableCell>*/}

                </TableRow>
                table_rows.push(r);
            }
        }

        return (
            <div className = "accounts_holder">
                {skill_queue_popup}
                {/*<Menu
                    id="simple-menu"
                    className = "accounts_group_menu"
                    anchorEl={this.state.menu_anchor}
                    keepMounted
                    open={Boolean(this.state.menu_anchor)}
                    onClose={this.handleMenuClose}
                >
                    <MenuItem className = "account_group_menu_item" onClick={() => {this.handleMenuClose(1)}}>1 - Default</MenuItem>
                    <MenuItem className = "account_group_menu_item" onClick={() => {this.handleMenuClose(2)}}>2 - Viper</MenuItem>
                    <MenuItem className = "account_group_menu_item" onClick={() => {this.handleMenuClose(3)}}>3 - Hatchling</MenuItem>
                    <MenuItem className = "account_group_menu_item" onClick={() => {this.handleMenuClose(4)}}>4 - Eggboi</MenuItem>
                    <MenuItem className = "account_group_menu_item" onClick={() => {this.handleMenuClose(5)}}>5 - Admin</MenuItem>
                </Menu>*/}
                <div className = "accounts_title">All Accounts</div>

                    <Table className = "accounts_table_container" aria-label="a dense table">
                        <TableHead className = "accounts_table_head">
                            <TableRow className = "accounts_table_row">
                            <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("character_index");}}>#</TableCell>
                                <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("account_name");}}>Account</TableCell>
                                <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("character_name");}}>Name</TableCell>
                                <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("corporation_name");}}>Corporation</TableCell>
                                <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("system");}}>Location</TableCell>
                                <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("system_tag");}}>Location</TableCell>
                                <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("structure");}}>Station</TableCell>
                                <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("ship");}}>Ship</TableCell>
                                <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("skillpoints_in_queue");}}>Training</TableCell>
                                <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("character_config_tag_1");}}>1</TableCell>
                                <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("character_config_tag_2");}}>2</TableCell>
                                <TableCell className = "accounts_table_head_row" onClick={() => {this.toggleSort("character_config_tag_3");}}>3</TableCell>
                                {/*<TableCell className = "accounts_table_head_row">Group Title</TableCell>*/}
                                {/*<TableCell className = "accounts_table_head_row">Last Web Login</TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody className = "accounts_table_body">
                            {table_rows}
                        </TableBody>
                    </Table>
            </div>
        )
    }
}