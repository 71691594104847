// Loading the react components
import React from 'react';

import "../../css/Timers/timers.css";

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button';

// Loading market components

const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const baseURL = "https://ireallyhatednsrecords.com/";

export default class TimerView extends React.Component {

    constructor(props) {
        super(props);

        this.deleteTrashIcon = this.deleteTrashIcon.bind(this);
    }

    deleteTrashIcon(value) {
        let datafields = { "id": value }

        let params = {
            character_id: this.props.props.character_id,
            character_auth_code: this.props.props.auth_code,
            data: datafields
        };

        let ref = this;

        // Taking the params and saving the new code and state...
        fetch(baseURL + "timer/delete", {
            method: "POST",
            body: JSON.stringify(params)
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            console.log("Loading timer info")
            console.log(myJson);

            alert("Deleted, please refresh");
        }).catch(error => {
            // Handle any errors that occurred during the fetch
            console.error('Fetch error:', error);
        });
    }

    loadPage() {
        this.props.props.history.push("/timers/create")
    }

    render() {

        if (this.props.timers == null) {
            return (<div className="timers_title">Loading...</div>)
        }

        let timers = [];
        timers.push(<TimerRow header={true} key={0} />);

        let timerData = this.props.timers;

        var sortedTimerItems = Object.keys(this.props.timers).map(function (key) {
            return [+timerData[key]["time"], timerData[key]];
        });

        sortedTimerItems.sort(function (first, second) {
            return first[0] - second[0];
        });

        console.log(sortedTimerItems);

        let i = 0;
        sortedTimerItems.forEach((timer) => {
            timer = timer[1]

            let effect = "N/A";
            if (timer.wormhole_class.length > 0) {
                effect = timer.wormhole_effects + " " + timer.wormhole_class + " > " + timer.wormhole_statics
            }

            i += 1;
            timers.push(
                <TimerRow
                    deleteTrashIcon={this.deleteTrashIcon}
                    key={i}
                    header={false}
                    status={timer.status}
                    location={timer.location}
                    name={timer.name}
                    type={timer.type}
                    structure={timer.structure}
                    owner={timer.owner}
                    details={timer.details}
                    effect={effect}
                    time={timer.time} />);
        });

        return (
            <div className="timerViewHolder">
                <div className="timers_title">Timers</div>
                <Button onClick={() => this.loadPage()} className="redirectToCreateButton"><div className="timerButtonText">New Timer</div></Button>

                <div className="timersRowPrimaryContainer">
                    {timers}
                </div>
            </div>
        )
    }
}

class TimerRow extends React.Component {

    constructor(props) {
        super(props);
    }

    formatNumberCorrectly(number) {
        if (+number < 10) {
            return "0" + number;
        }
        return number;
    }

    render() {
        if (this.props.header == true) {
            return <div className="timerRowContainer">
                <div className="timerRowHolderHeaderTime">
                    Time (EVE) <FontAwesomeIcon icon="filter" className="timerFilterIcon" style={{ marginRight: 15 }} onClick={() => { alert("Hello") }} />
                </div>
                <div className="timerRowHolderHeader">
                    Status <FontAwesomeIcon icon="filter" className="timerFilterIcon" style={{ marginRight: 15 }} onClick={() => { alert("Hello") }} />
                </div>
                <div className="timerRowHolderHeader">
                    System <FontAwesomeIcon icon="filter" className="timerFilterIcon" style={{ marginRight: 15 }} onClick={() => { alert("Hello") }} />
                </div>
                <div className="timerRowHolderHeader">
                    Effect <FontAwesomeIcon icon="filter" className="timerFilterIcon" style={{ marginRight: 15 }} onClick={() => { alert("Hello") }} />
                </div>
                <div className="timerRowHolderHeader">
                    Name <FontAwesomeIcon icon="filter" className="timerFilterIcon" style={{ marginRight: 15 }} onClick={() => { alert("Hello") }} />
                </div>
                <div className="timerRowHolderHeader">
                    Structure <FontAwesomeIcon icon="filter" className="timerFilterIcon" style={{ marginRight: 15 }} onClick={() => { alert("Hello") }} />
                </div>
                <div className="timerRowHolderHeader">
                    Type <FontAwesomeIcon icon="filter" className="timerFilterIcon" style={{ marginRight: 15 }} onClick={() => { alert("Hello") }} />
                </div>
                <div className="timerRowHolderHeader">
                    Owner <FontAwesomeIcon icon="filter" className="timerFilterIcon" style={{ marginRight: 15 }} onClick={() => { alert("Hello") }} />
                </div><div className="timerRowHolderHeader">
                    Details <FontAwesomeIcon icon="filter" className="timerFilterIcon" style={{ marginRight: 15 }} onClick={() => { alert("Hello") }} />
                </div>
            </div>
        }

        let localDate = new Date(+this.props.time * 1000);
        let eveDate = Date.UTC(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), localDate.getUTCHours(), localDate.getUTCMinutes(), localDate.getUTCSeconds());

        eveDate = new Date(eveDate)

        let shownEveDate = months[eveDate.getUTCMonth()] + " " + weekday[eveDate.getUTCDay()] + " " + this.formatNumberCorrectly(eveDate.getUTCDate()) + "th " + this.formatNumberCorrectly(eveDate.getUTCHours()) + ":" + this.formatNumberCorrectly(eveDate.getUTCMinutes()) + " ET";
        let nowTime = new Date();

        var diff = Math.floor((localDate.getTime() - nowTime.getTime()) / 3600000)
        if (Math.abs(diff) > 0) {
            diff = diff + "ish hours"
        }
        else {
            diff = Math.floor((localDate.getTime() - nowTime.getTime()) / 60000)
            diff = diff + " min";
        }

        let trashCan = null;
        if (this.props.details === "Manual") {
            trashCan = <FontAwesomeIcon icon="trash" className="timerFilterTrashIcon" onClick={() => { this.props.deleteTrashIcon(this.props.time) }} />;
        }

        return (
            <div className={"timerRowContainer" + this.props.status}>
                <div className="timerRowHolderTime">
                    <a href={"https://time.nakamura-labs.com/?#" + this.props.time} target="_blank">{shownEveDate} ({diff})</a>
                </div>
                <div className="timerRowHolder">
                    <p>{this.props.status}</p>
                </div>
                <div className="timerRowHolder">
                    <p>{this.props.location}</p>
                </div>
                <div className="timerRowHolder">
                    <p>{this.props.effect}</p>
                </div>
                <div className="timerRowHolder">
                    <p>{this.props.name}</p>
                </div>
                <div className="timerRowHolder">
                    <p>{this.props.structure}</p>
                </div>
                <div className="timerRowHolder">
                    <p>{this.props.type}</p>
                </div>
                <div className="timerRowHolder">
                    <p>{this.props.owner}</p>
                </div>
                <div className="timerRowHolder">
                    <p>{this.props.details}</p>
                    {trashCan}
                </div>
            </div>
        )
    }
}