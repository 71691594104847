// Loading the react components
import React from 'react';

// CSS
import "../../css/pledge.css"

export default class PledgeProfile extends React.Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>Pledge Profile</div>
        )
    }
}