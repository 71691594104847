// Loading the react components
import React from 'react';

import "../../css/Timers/timers.css";

// Loading market components
import TimerView from "./TimerView";
import TimerCreate from "./TimerCreate";

const baseURL = "https://ireallyhatednsrecords.com/";

export default class TimerMain extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            "timers": []
        }
    }

    componentDidMount() {
        document.title = "No Vacancies - Timer Board";
        this.callGetTimers()
    }

    callGetTimers() {
        let params = {
            character_id: this.props.character_id,
            character_auth_code: this.props.auth_code
        };

        let ref = this;

        // Taking the params and saving the new code and state...
        fetch(baseURL+"timer/get", {
            method: "POST",
            body: JSON.stringify(params)
        }).then(function(response) {
            return response.json();
        }).then(function(myJson) {
            console.log("Loading timer info")
            console.log(myJson);

            ref.setState({timers: myJson})
        });
    }

    render() {

        if (this.state.timers == null) {
            return (<div className = "main_holder"><div className="timers_title">Loading</div></div>)
        }

        if (this.state.timers["code"] == 400) {
            return (<div className = "main_holder"><div className="timers_title">Unauthorized</div></div>)
        }


        let active_view = <TimerView timers={this.state.timers} props={this.props}/>
        
        // Picking what to render
        let path = this.props.location.pathname
        let base_target = "/timers"
        if (path == base_target + "/create" ) {
            active_view = <TimerCreate props={this.props}/>
        }

        return (
            <div className = "main_holder">
                {active_view}
            </div>
        )
    }
}

