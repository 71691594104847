// Loading the react components
import React from 'react';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';

import "../../css/Timers/timers.css";

// Loading market components

const baseURL = "https://ireallyhatednsrecords.com/";

export default class TimerCreate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            "status": "",
            "rawData": "",
            "type": "",
            "structure": "",
            "owner": ""
        }

        this.submit = this.submit.bind(this);
    }

    onSelectType(e) {
        this.setState({"type": e.target.value});
    }

    onSelectStructure(e) {
        this.setState({"structure": e.target.value});
    }

    onChangeTextArea(e) {
        this.setState({"rawData": e.target.value});
    }

    onSelectStatus(e) {
        this.setState({"status": e.target.value});
    }

    onChangeStructureOwner(e) {
        this.setState({"owner": e.target.value});
    }

    submit() {

        let name = "";
        let location = "";
        let timer = "";

        if (this.state.rawData.length <= 0) {
            alert("You're missing raw data field");
            return;
        }
        if (this.state.status.length <= 0) {
            alert("You're missing status field");
            return;
        }
        if (this.state.type.length <= 0) {
            alert("You're missing type field");
            return;
        }
        if (this.state.structure.length <= 0) {
            alert("You're missing structure field");
            return;
        }
        if (this.state.owner.length <= 0) {
            alert("You're missing owner field");
            return;
        }

        const splitData = this.state.rawData.split("\n");
        location = (splitData[0].split(" - ")[0]);
        name = (splitData[0].split(" - ")[1]);

        let dateData = splitData[2].split(" ")[2];
        let timeData = splitData[2].split(" ")[3];

        dateData = dateData.replaceAll(".","-");

        let finalDateString = dateData+"T"+timeData + ".000Z";
        let dateFull = Date.parse(finalDateString);

        const datafields = {
            "status": this.state.status,
            "location": location,
            "name": name,
            "type": this.state.type,
            "structure": this.state.structure,
            "owner": this.state.owner,
            "details": "Manual",
            "time": dateFull
        }

        console.log(this.props);
        console.log(this);

        let params = {
            character_id: this.props.props.character_id,
            character_auth_code: this.props.props.auth_code,
            data: datafields
        };

        console.log(params);

        let ref = this;

        // Taking the params and saving the new code and state...
        fetch(baseURL+"timer/create", {
            method: "POST",
            body: JSON.stringify(params)
        }).then(function(response) {
            return response.json();
        }).then(function(myJson) {
            console.log("Saving timer info")
            console.log(myJson);
            alert("Success; navigate back and refresh");
        });
    }

    render() {

        return (
            <div className="timerViewHolder">
                <div className="timers_title">Create Timer</div>
                <Paper className="timersCreateTable">
                    <div className="timerCreateLabel">Select 'Copy' On the Structure (See Below)</div>
                    <img className = "timerInstructionsPhotos" src={require("../../assets/img/timer_save_instructions.png")} />
                    <div className="timerCreateLabel">Your data should look like the following example</div>
                    <img className = "timerInstructionsPhotos" src={require("../../assets/img/timer_paste.PNG")} />
                    <div className="timerCreateLabel">Paste Below</div>
                    <TextareaAutosize value={this.state.rawData} className="timerTextField" variant="outlined" label="" onChange={(e) => {this.onChangeTextArea(e);}}></TextareaAutosize>
                    <div className="timerCreateLabel">Type</div>
                    <Select className="timerTextDropdown" value = {this.state.type} onChange={(e) => {this.onSelectType(e);}} variant='outlined' label="Status">
                        <MenuItem value={"Friendly"}>Friendly</MenuItem>
                        <MenuItem value={"Hostile"}>Hostile</MenuItem>
                    </Select>
                    <div className="timerCreateLabel">Structure Type</div>
                    <Select className="timerTextDropdown" value = {this.state.structure} onChange={(e) => {this.onSelectStructure(e);}} variant='outlined' label="Status">
                        <MenuItem value={"Astrahus"}>Astrahus</MenuItem>
                        <MenuItem value={"Fortizar"}>Fortizar</MenuItem>
                        <MenuItem value={"Raitaru"}>Raitaru</MenuItem>
                        <MenuItem value={"Azbel"}>Azbel</MenuItem>
                        <MenuItem value={"Athanor"}>Athanor</MenuItem>
                        <MenuItem value={"Tatara"}>Tatara</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                    <div className="timerCreateLabel">Status</div>
                    <Select className="timerTextDropdown" value = {this.state.status} onChange={(e) => {this.onSelectStatus(e);}} variant='outlined' label="Status">
                        <MenuItem value={"Armor"}>Armor</MenuItem>
                        <MenuItem value={"Hull"}>Hull</MenuItem>
                        <MenuItem value={"Armor/Hull"}>Armor/Hull</MenuItem>
                    </Select>
                    <div className="timerCreateLabel">Structure Owning Corporation</div>
                    <TextField value={this.state.owner} className="timerTextField" variant="outlined" label="" onChange={(e) => {this.onChangeStructureOwner(e);}}></TextField>
                    <Button onClick={() => this.submit()} className="submitNewTimerButton">
                        <div className="timerButtonText">Submit Timer</div>
                    </Button>
                </Paper>
            </div>
        )
    }
}