// Loading the react components
import React from 'react';

// CSS
import "../../css/pledge.css"

// This class will display the homepage for the pledge class
// This will contain the 'apply' fields, as well as the banners and display shit

export default class PledgeHome extends React.Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>Pledge Home</div>
        )
    }
}